"use client";

import Script from "next/script";
import { useEffect, useRef, useState } from "react";
import { useTimeout } from "usehooks-ts";

import { Spinner } from "../spinner/spinner";

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Vimeo: any;
  }
}

export const vimeoBaseURL = "https://player.vimeo.com/video/";

function Placeholder() {
  return (
    <div className="absolute inset-0 h-full w-full">
      <div
        role="status"
        className="flex flex-col gap-2 text-white items-center justify-center h-full bg-gray-300 rounded-lg animate-pulse"
      >
        <Spinner />
        <span className="sr-only">Loading</span>
      </div>
    </div>
  );
}

export const VimeoPlayer = function ({
  autoplay = false,
  id,
  finishedCallback,
  shouldLoad = false,
  vimeoQueryParams = `?color=2f2f2f&title=0&byline=0&portrait=0&texttrack=en`,
}: {
  autoplay?: boolean;
  id: string;
  finishedCallback?: () => void;
  shouldLoad?: boolean;
  vimeoQueryParams?: string;
}) {
  const [forceLoad, setForceLoad] = useState(false);
  const [iframeDidLoad, setIFrameDidLoad] = useState(false);
  const [scriptDidLoad, setScriptDidLoad] = useState(false);
  const iframe = useRef<HTMLIFrameElement>(null);
  // TODO: cruft; can be cleaned up?
  const qp = id.match(/\?/g)
    ? vimeoQueryParams.replace("?", "&")
    : vimeoQueryParams;
  const src = `${vimeoBaseURL}${id}${qp}${autoplay ? "&autoplay=1" : ""}`;

  const load = () => {
    setForceLoad(true);
  };

  useTimeout(load, 8000);

  useEffect(
    function () {
      if (!scriptDidLoad) {
        return;
      }

      const didEnd = function () {
        finishedCallback?.();
      };
      if (iframe.current && iframe.current.src) {
        const player = new window.Vimeo.Player(iframe.current);
        player.on("ended", didEnd);

        return function () {
          player.off("ended", didEnd);
        };
      } else {
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        return () => {};
      }
    },
    [scriptDidLoad, finishedCallback],
  );

  if (forceLoad || shouldLoad) {
    return (
      <>
        {!iframeDidLoad || !scriptDidLoad ? <Placeholder /> : null}
        <iframe
          allowFullScreen
          allow="autoplay; fullscreen"
          className="absolute inset-0 h-full w-full"
          ref={iframe}
          src={src}
          style={{ border: 0 }}
          title="video player"
          onLoad={() => {
            setIFrameDidLoad(true);
          }}
        />
        <Script
          src="https://player.vimeo.com/api/player.js"
          strategy="lazyOnload"
          onLoad={() => {
            setScriptDidLoad(true);
          }}
          onReady={() => {
            setScriptDidLoad(true);
          }}
        />
      </>
    );
  }
};
